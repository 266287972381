@import "./reset.scss";
@import "./fonts.scss";
@import "./mixins";

:root {
  --primary: #000000;
  --secondary: #ff8383;

  font-family: Aileron;
}

sup {
  font-size: xx-small;
  vertical-align: super;
}

// Aileron variation
// =============
@font-face {
  font-family: Aileron;
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/Aileron-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: Aileron;
  font-weight: 200;
  src: url("../fonts/Aileron-Light.otf") format("opentype");
}

@font-face {
  font-family: Aileron;
  font-weight: 400;
  src: url("../fonts/Aileron-Regular.otf") format("opentype");
}

@font-face {
  font-family: Aileron;
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Aileron-Italic.otf") format("opentype");
}

@font-face {
  font-family: Aileron;
  font-weight: 900;
  src: url("../fonts/Aileron-Black.otf") format("opentype");
}

@font-face {
  font-family: Aileron;
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Aileron-BlackItalic.otf") format("opentype");
}

@import "src/styles/base.scss";

.PageContainer {
  position: relative;
  z-index: 2;
  margin: 100px 23px 0px 23px;

  @media (min-width: 700px) {
    margin-top: 150px;
  }

  @media (min-width: 700px) {
    width: 660px;
    margin: 276px auto 20px auto;
  }

  p,
  h3,
  ul > li,
  ol > li,
  img {
    margin-bottom: 30px;
  }
}

.Button {
  margin-bottom: 24px;
}

.ArrowToScroll {
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 60px;
  width: 60px;
  cursor: pointer;
  transition: width 0.2s, height 0.2s;
  z-index: 10;

  &.Hidden {
    width: 0px;
    height: 0px;
  }
}

.Pagination {
  width: 100%;
  display: flex;
  margin-bottom: 63px;
  margin-top: 70px;

  @media (min-width: 700px) {
    margin-bottom: 150px;
  }
}

.LinkChangePage {
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &Next {
    margin-left: auto;
  }

  & > p {
    margin-bottom: 0;
  }

  .ArrowBefore {
    height: 15px;
    width: auto;
    margin-right: 10px;
    transform: rotate(180deg);
  }
  .ArrowNext {
    height: 15px;
    width: auto;
    margin-left: 10px;
  }
}

@mixin desktop {
  @media (min-width: 700px) {
    @content;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin opacityTransition($delay: 0.2s) {
  animation: linear;
  animation-name: opacity;
  animation-duration: 0.3s;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

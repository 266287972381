@import "src/styles/base.scss";

.Button {
  background-color: transparent;
  font-family: Aileron;
  border: none;
  position: relative;
  cursor: pointer;
  padding: 0;
  text-align: left;
  width: fit-content;

  &:hover {
    .Background {
      transition: transform 0.5s;

      &.medium {
        transform: translate(3px, 3px);
      }

      &.big {
        transform: translate(7px, 3px);
      }
    }

    .Border {
      transition: transform 0.5s;

      &.medium {
        transform: translate(-3px, -3px);
      }

      &.big {
        transform: translate(-7px, -4px);
      }
    }
  }

  .Text {
    font-weight: 900;
    z-index: 1;
    position: relative;
    display: block;

    &.medium {
      font-size: 19px;
      letter-spacing: 0.4px;
      padding: 13px 18px 18px 16px;
    }

    &.big {
      font-size: 21px;
      letter-spacing: 0.2px;
      padding: 14px 38px 25px 29px;
    }

    .Subtext {
      display: none;

      &.big {
        display: block;
        font-weight: 200;
        font-style: italic;
        font-size: 21px;
        letter-spacing: 0.2px;
      }
    }
  }

  .Background {
    transition: transform 0.3s;
    background-color: var(--secondary);
    position: absolute;
    left: 0;
    top: 0;

    &.medium {
      width: calc(100% - 9px);
      height: calc(100% - 9px);
    }

    &.big {
      width: calc(100% - 15px);
      height: calc(100% - 11px);
    }
  }

  .Border {
    transition: transform 0.5s;
    background-color: transparent;
    right: 0;
    bottom: 0;
    position: absolute;

    &.medium {
      width: calc(100% - 6px);
      height: calc(100% - 5px);
      border: 3px solid var(--primary);
    }

    &.big {
      width: calc(100% - 13px);
      height: calc(100% - 7px);
      border: 5px solid var(--primary);
    }
  }
}

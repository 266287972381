@import "src/styles/base.scss";

.HomeContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 69px;

  .TitleSection {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;

    .Actions {
      display: flex;
      align-items: flex-end;
    }

    .LogoLqdn {
      width: 67px;
      height: 67px;
      margin-right: 32px;
    }

    .ButtonDownload {
      margin-left: 6px;
    }
  }

  .IndexSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 17px;
    justify-content: center;

    .Link {
      width: fit-content;
    }

    .Introduction {
      margin-left: 67px;
    }

    .Part1 {
      margin-left: 215px;
    }

    .Part3 {
      margin-left: 185px;
    }

    .Conclusion {
      margin-left: 440px;
    }
  }
}

@import "src/styles/base.scss";

.Index {
  display: flex;
  gap: 10px;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  width: fit-content;

  @media (min-width: 1240px) {
    justify-content: center;
    width: 100%;
  }
}

.IndexContainer {
  width: 100%;

  @media (max-width: 1240px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  opacity: 0;
  background-color: white;
  @include opacityTransition;

  &.Small {
    @include opacityTransition(0s);
  }
}

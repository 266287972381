@import "src/styles/base.scss";

.NavbarContainer {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;

  &.NavOpen {
    z-index: 10;
  }
}

.TitleLink {
  color: var(--primary);
  text-decoration: none;
}

.Title {
  width: 160px;
}

.ChapterIndex {
  font-style: normal;
  font-weight: bolder;
  margin-right: 32px;
}

.TextTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ChapterName {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  margin-right: 10px;
}

.Arrow {
  &Right {
    position: absolute;
    left: 176px;
    right: 0%;
    transition: 0.5s;
    opacity: 1;

    &.NavOpen {
      left: calc(100% - 28px - 10px);
      transform: translateX(0%);
      cursor: pointer;

      &.IsDisplayNone {
        opacity: 0;
        cursor: auto;
      }
    }
  }

  &Left {
    min-width: 28px;
    min-height: 55px;
    margin-right: 10px;
    transform: rotate(180deg);
    cursor: pointer;

    &.IsDisplayNone {
      opacity: 0;
      cursor: auto;
    }
  }
}

.LogoHeaderContainer {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 12px;
  padding-right: 0px;
  position: relative;

  &.NavOpen {
    justify-content: space-between;
  }
}

.ChapterTitle {
  display: flex;
  align-items: center;
  width: 880px;
  justify-content: space-between;
  opacity: 0;
  padding-right: 10px;

  &.NavOpen {
    @include opacityTransition;
  }
}

.BackgroundWhite {
  height: calc(100% - 164px);
  background-color: white;
  opacity: 0;
  position: absolute;
  transition: 0.3s;

  &.NavOpen {
    opacity: 1;
    transition: 0.3s 0.2s;
    width: 100%;
  }
}

.Hitbox {
  height: 60px;
  width: 100%;
  position: absolute;
}

.LinearGradient {
  height: 164px;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0;

  &.NavOpen {
    opacity: 1;
    transition: 0.3s 0.2s;
  }
}

.Location {
  border: 5px solid black;
  width: fit-content;
  padding: 8px;
  position: absolute;
  top: 100%;
  z-index: 1;
}

.ChapterNameText {
  margin-bottom: 10px;
}

.PhantomArrow {
  width: 28px;
}

@import "src/styles/base.scss";

.NoteMark {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  cursor: pointer;
  margin-bottom: -3px;
  position: relative;
  z-index: 2;

  & > circle {
    stroke: var(--secondary);
  }

  & > path {
    fill: var(--secondary);
  }
}

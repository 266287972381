.Container {
  border: 2px solid black;
  margin-bottom: 30px;
  padding: 30px;
  padding-bottom: 0px;

  & > p {
    margin-bottom: 30px;

    &:nth-last-of-type() {
      margin-bottom: 0;
    }
  }
}

.CloseIcon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
  height: 15px;
  & > path {
    stroke: white;
  }
}

.ModalContainer {
  color: white;
  position: relative;
  background-color: black;
  max-width: 500px;
  margin: 22px;
  height: auto;
  z-index: 999;
  padding: 40px;
  word-break: break-word;

  a,
  p {
    margin-bottom: 0;
  }
}

.ModalBackgroundScreen {
  position: fixed;
  color: white;
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

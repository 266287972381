.LinkTextIndex {
  text-decoration: none;
  color: black;
}

.IndexText {
  padding: 5px;
  transition: 0.1s;
  font-size: 18px;
  box-sizing: border-box;

  @media (min-width: 700px) {
    font-size: 23px;
  }

  &.CurrentLocation {
    outline: 3px solid black;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--secondary);
    transition: 0.1s;
  }
}

.h1 {
  font-family: Aileron;
  font-weight: 200;
  font-style: italic;
  font-size: 46px;
  line-height: 120%;
  text-transform: uppercase;

  & > i {
    font-style: italic;
  }

  @media (min-width: 700px) {
    font-size: 75px;
    letter-spacing: 5px;
  }
}

.h2 {
  font-family: Aileron;
  font-size: 17px;
  font-weight: 200;
  font-style: italic;
  letter-spacing: 2px;
  line-height: 118%;
  text-transform: uppercase;

  & > i {
    font-style: italic;
  }

  @media (min-width: 700px) {
    font-size: 30px;
  }
}

.h3 {
  font-family: Aileron;
  font-size: 16px;
  font-weight: 900;

  & > i {
    font-style: italic;
  }

  @media (min-width: 700px) {
    font-size: 26px;
  }
}

.h4 {
  font-family: Aileron;
  font-size: 13px;
  line-height: 122%;
  font-weight: 900;

  & > i {
    font-style: italic;
  }

  @media (min-width: 700px) {
    font-size: 16px;
  }
}

.body {
  font-family: Aileron;
  font-size: 16px;
  line-height: 120%;
  font-weight: 400;
  letter-spacing: 0.3px;

  & > i {
    font-style: italic;
  }

  @media (min-width: 700px) {
    font-size: 18px;
  }
}

.subtitle {
  font-family: Aileron;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;

  & > i {
    font-style: italic;
  }

  @media (min-width: 700px) {
    font-size: 19px;
  }
}

@import "src/styles/base.scss";

.NavbarAndIndexContainer {
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
}

.NavbarContainer {
  border-bottom: 3px solid black;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 14px;

  @media (min-width: 700px) {
    padding: 0px 26px;
  }

  &.NavOpen {
    border-bottom: none;
    flex-direction: column;
  }
}

.Title {
  padding: 20px 0px;

  @media (min-width: 700px) {
    padding: 15px 0px;
  }
}

.CurrentLocation {
  align-self: end;
  border: 3px solid black;
  border-bottom: none;
  padding: 6px;
  cursor: pointer;

  @media (min-width: 700px) {
    padding: 8px;
    margin-right: 21px;
  }

  &.NavOpen {
    display: none;
  }
}

.TitleLink {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.TitleTextContainer {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  opacity: 0;
  justify-content: space-between;
  @include opacityTransition(0s);
  height: 98px;

  @media (min-width: 700px) {
    height: 152px;
    margin-bottom: 24px;
  }
}

.ChapterPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.TitleText {
  display: flex;
  align-items: center;
}

.BigTexts {
  font-weight: bolder;
  margin-left: 20px;
  margin-right: 14px;
  font-style: normal;

  @media (min-width: 700px) {
    margin-left: 32px;
    margin-right: 16px;
  }
}

.ChapterNameText {
  margin-bottom: 10px;
}

.Arrows {
  min-height: 21px;
  min-width: 11px;
  cursor: pointer;

  @media (min-width: 700px) {
    min-height: 50px;
    min-width: 39px;
  }

  &.ArrowLeft {
    transform: rotate(180deg);

    @media (min-width: 700px) {
      margin-right: 16px;
    }
  }

  &.ArrowRight {
    margin-left: 16px;

    @media (min-width: 700px) {
      margin-left: 24px;
    }
  }

  &.ArrowHidden {
    opacity: 0;
    cursor: default;
  }
}

.NavbarFantom {
  width: 100%;
  height: 100vh;
}
